import type { FC } from 'react';
import React, { useContext } from 'react';
import { css } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { N800 } from '@atlaskit/theme/colors';

import { PageCardContext } from '../PageCardContext';

type SingleLineProp = { singleLine?: boolean };

const titleCompact = css({
	fontSize: '16px',
	lineHeight: '20px',
	fontWeight: 500,
});

const titleEndOfPage = css({
	fontSize: '12px',
	lineHeight: '16px',
	fontWeight: 600,
});

const titleSingleLine = css({
	display: 'block',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
});

const titleStyle = css({
	color: token('color.text', N800),
	fontSize: '20px',
	lineHeight: '24px',
	fontWeight: 400,
	textDecoration: 'none',
	outline: 'none',

	'&:active, &:visited, &:hover, &:focus': {
		color: token('color.text', N800),
		textDecoration: 'none',
	},
});

export const Title: FC<SingleLineProp> = ({ singleLine }) => {
	const {
		id,
		title: { text },
		appearance,
	} = useContext(PageCardContext);
	const compact = appearance === 'compact-list';
	const endOfPage = appearance === 'end-of-page';
	return (
		<div
			css={[
				titleStyle,
				compact && titleCompact,
				endOfPage && titleEndOfPage,
				singleLine && titleSingleLine,
			]}
			id={`content-title-${id}`}
			role="heading"
			aria-level={2}
		>
			{text}
		</div>
	);
};
